import AjaxError from "./AjaxError";

class ErrorHandler {

    getMessage(error) {
        var error = new AjaxError(error);

        return error.getMessage();
    }

    toast(error) {
        var error = new AjaxError(error);
        if (!error.isConnectionAborted()) {
            Toaster.error(error.getMessage());
        }
    }

    alert(error) {
        var error = new AjaxError(error);

        if (!error.isConnectionAborted()) {
            Alert.error(error.getMessage());
        }
    }

    static handle(error, feedbackMethod) {
        var handler = new ErrorHandler;
        if (feedbackMethod == 'toast') {
            return handler.toast(error);
        }

        handler.alert(error);
    }
}

export default ErrorHandler;