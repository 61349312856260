// bootstrap JS file
import './bootstrap.js';
import '../sass/app.scss';
var reveal = null;

window.Overlay = createApp({
    data() {
        return {
            visible: false,
            text: null
        };
    },

    methods: {
        reveal(text = null) {
            this.visible = true;
            this.text = text;
        },

        hide() {
            this.visible = false;
        },

        show(text = null) {
            this.reveal(text);
        }
    }
}).mount('#loading');

Echo.channel('admin-channel')
    .listen('ErrorReport', (e) => { console.log(e); });

Echo.channel('forum-channel')
    .listen('NewForumPost', (e) => {
        Toaster.show(e.post.user.name + ' posted in the forum');
    });

$(document).ready(function () {
    // inject CSRF token in POST requests
    window.$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(document).foundation();

    // global reveal object
    window.Reveal = new Foundation.Reveal($('#reveal'), {
        animationIn: 'slide-in-down fast',
        animationOut: 'slide-out-up fast'
    });


    $(document).on('click', '.prompt-lineups', function (event) {
        var url = $(this).data('url');
        $.ajax({
            url: url
        })
            .done(function (response) {
                Reveal.$element.html(response);
                Reveal.open();
            });
    });

    $(document).on('click', '.prompt-waiver', function (event) {
        var url = $(this).data('url');
        $.ajax({
            url: url
        })
            .done(function (response) {
                Reveal.$element.html(response);
                Reveal.open();
            });
    });

    $(document).on('click', '[data-href-trigger]', function (event) {
        RequestHandler.handle(event);
    });

    $('.update-matchup').click(function (event) {
        event.preventDefault();
        toggleOverlay();
        $.ajax({
            url: '/matchups/update/' + $(this).data('date'),
            type: 'GET',
            dataType: 'json',
        })
            .done(function (response) {
                Alert.success(response.message);
            })
            .always(function (response) {
                toggleOverlay();
            });
    });

    $(document).on('click', '#get-linetmates', function (event) {
        event.preventDefault();
        Overlay.reveal('Chargement des trios');
        $.ajax({
            url: $(this).attr('href'),
            dataType: 'json'
        })
            .done(function (response) {
                if (response.success) {
                    $('#linemates').html(response.data.html);
                };
            })
            .always(function (response) {
                Overlay.hide();
            });
    });

    $(document).on('click', '.player-link', function (event) {
        event.preventDefault();
        playerInfo($(this).attr('href'));
    });// player-link

    $(document).on('click', '#update-player', function (event) {
        event.preventDefault();
        $.ajax({
            url: '/players/update-owner',
            type: 'POST',
            data: {
                owner: $('#owner-selector').val(),
                playerId: $('#player_id').val(),
                _token: $('#token').val()
            },
        })
            .done(function () {
                Alert.success('Owner updated').then(() => location.reload());
            });
    });

    $('.forum-post-trigger').click(function (event) {
        event.preventDefault();
        var $post = $(this);
        axios.post('/forum/flag-post', {
            post: $post.data('post-id'),
            attribute: $post.data('trigger'),
            _token: $('[name="_token"]').val()
        })
            .then(response => {
                if (!response.success) {
                    throw response.message;
                }
                Alert.success(response.message);
                $post.toggleClass('active');
                $post.attr('data-active', response.data.active);
            })
            .catch(exception => {
                var message = typeof exception == 'object' ? exception.message : exception;
                Alert.error(message);
            });
    });

    $(document).on('click', '.toggle-all', function () {
        var checked = $(this).prop('checked');
        $('[name="playing[]"]').prop('checked', true);
    });

    $('.file-input-wrapper').click(function (event) {
        event.preventDefault();
        $('#avatar-file-input').trigger('click');
    });

    $('#avatar-file-input').change(function () {
        var fileName = $(this).val().split("\\");
        fileName = fileName[fileName.length - 1];
        $('#file-name').text(fileName);
    });

    $(document).on('click', '.score-reveal', function (event) {
        event.preventDefault();
        var $game = $(this);
        $.ajax({
            url: $game.attr('href')
        })
            .done(function (response) {
                Reveal.$element.html(response);
                Reveal.open();
            });
    });

    $(document).on('submit', '#add-to-lineup', function (event) {
        event.preventDefault();
        var $form = $(this);
        axios.post($form.attr('action'), $form.serialize())
            .then(response => {
                if (!response.success) {
                    throw response.message;
                }

                Alert.success(response.message).then(() => location.reload());
            })
            .catch(exception => {
                console.log(exception);
                console.log(Alert);
                var message = typeof exception == 'object' ? exception.message : exception;
                Alert.error(message);
            });
    });

    $(document).on('click', '.tradeblock-toggle', function (event) {
        event.preventDefault();
        $.ajax({
            url: '/tradeblock/toggle-player',
            type: 'POST',
            dataType: 'json',
            data: {
                playerID: $(this).data('player-id'),
                _token: $('#token').val()
            },
        })
            .done(function (response) {
                Alert.success(response.message).then(() => location.reload());
            });
    });

    $(document).on('click', '[data-copiable]', function (event) {
        event.preventDefault();
        Clipboard.copy($(this).data('copy'));
    });
});

function addToTeam(playerID) {
    $.ajax({
        url: '/players/add',
        data: { player: playerID }
    })
        .done(function (response) {
            Reveal.$element.html(response);
            Reveal.open();
        });
}

function loadLineup(date, dateString) {
    $.ajax({
        url: '/lineup/edit-html/' + date,
    })
        .done(function (response) {
            $('#lineup-players').fadeOut('fast', function () {
                $(this).html(response.data.html).fadeIn();
            });
            $('.date-label').text(dateString);
        });
}

function showAckBannerWithMessage(message, delay) {
    $('#ack-message').text(message).promise().done(function () {
        $('#ack-banner').slideToggle();
    });

    timeOutDelay = typeof delay == 'undefined' ? 5000 : delay;

    setTimeout(function () {
        $('#ack-banner').slideToggle();
    }, timeOutDelay);
}

function toggleOverlay() {
    Overlay.show = !Overlay.show;
}

function addToLineup(playerId) {
    axios.post('/lineup/add-player', {
        player: playerId
    })
        .then(response => {
            if (!response.success) {
                throw response.message;
            }
            Alert.success(response.message);
        })
        .catch(exception => {
            var message = typeof exception == 'object' ? exception.message : exception;
            Alert.error(message);
        });
}

function playerInfo(url) {
    Overlay.show();
    $.ajax({
        url: url
    })
        .done(function (response) {
            Reveal.$element.html(response);
            Reveal.open();
            Reveal.$element.foundation();
        })
        .fail(function () {
            Alert.error('No info available for this player');
        })
        .always(function () {
            Overlay.hide();
        });
}

function dataSelector(selector, value) {
    // var selector = "data-"+selector;
    var selector = `data-${selector}`;
    if (value) {
        selector += `="${value}"`;
    }

    return `[${selector}]`;
}