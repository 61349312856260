import ErrorHandler from "./ErrorHandler";

class RequestHandler {

    basic($el, feedbackMethod) {
        if ($el.data('show-loading') !== undefined) {
            Overlay.show($el.data('show-loading'));
        }

        if ($el.attr('href')) {
            var config = {
                method: 'get',
                url: $el.attr('href')
            };
        } else {
            var config = {
                method: $el.attr('method').toLowerCase(),
                url: $el.attr('action'),
                data: $el.serialize()
            };
        }

        axios(config)
            .then(response => {
                if (!response.success) {
                    throw response.message;
                }

                if (response.data.blocks) {
                    console.log(response.data);
                    for (const block in response.data.blocks) {
                        $('#'+block).html(response.data.blocks[block]);
                    }
                }

                this.provideFeedback(feedbackMethod, response)
                    .then(() => {
                        if (response.data.reload || $el.data('reload') !== undefined) {
                            window.location.reload();
                        }
                    })
                    .catch((reason) => console.log(reason));
            })
            .catch((error) => {
                ErrorHandler.handle(error, 'alert');
            })
            .then(() => Overlay.hide());
    }

    provideFeedback(feedbackMethod, response) {
        if (!feedbackMethod) {
            if (response.result == 'success' && response.data.reload) {
                return new Promise(resolve => resolve(true));
            }

            feedbackMethod = response.success ? 'toast' : 'alert';
        }

        switch (feedbackMethod.toLowerCase()) {
            case 'toast':
                var message = response.message ? response.message : 'Success';
                Toaster.show(message, response.type !== undefined ? response.type : 'error');
                break;
            default:
                Alert.show(response.message, response.type);
        }

        return new Promise(resolve => resolve(true));
    }

    handle(event) {
        event.preventDefault();
        let $el = $(event.target);

        if ($el.data('confirm')) {
            Alert.confirm($el.data('confirm'))
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.basic($el, $el.data('feedback'));
                        }
                    });
        } else {
            this.basic($el, $el.data('feedback'));
        }
    }
}

export default RequestHandler;