export default class Util {
    static removeFromArray(array, index) {
        array.splice(index, 1);
    }

    static randomString(length = 7) {
        return Math.random().toString(36).substring(length);
    }

    static ucfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static isNumeric(value) {
        return !isNaN(parseFloat(value));
    }

    static stringContains(needle, haystack) {
        return haystack.indexOf(needle) > -1;
    }

    static inArray(needle, haystack) {
      return haystack.indexOf(needle) > -1;
    }

    static objectIsEmpty(object) {
        return Object.getOwnPropertyNames(object).length === 0;
    }
}