class AjaxError {

    constructor(error) {
        this.error = error;
        this.CONNECTION_ABORTED = 'ECONNABORTED';
    }

    isConnectionAborted() {
        if (!this.isObject()) {
            return false;
        }

        return this.error.code == this.CONNECTION_ABORTED;
    }

    getMessage() {
        if (!this.isObject()) {
            return this.error;
        }

        if (this.error instanceof Error && this.error.hasOwnProperty('response')) {
            return this.error.response.data.message;
        }

        return this.error.message;
    }

    isObject() {
        return typeof this.error === 'object';
    }

}

export default AjaxError;