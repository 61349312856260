/**
 * Ack response object
 */
class Ack {

    constructor(response) {
        if (response) {
            this.message = response.message;
            this.title = response.title;
            this.data = response.data;
            this.type = response.type;
            this.result = response.result;
            this.success = response.success;
            this.statusCode = response.statusCode;
        }
    }

    isWarning() {
        return this.result == 'warning';
    }

}

export default Ack;