// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
/**
 * Sweetalert wrapper
 */
class Alert {

    info(message, options = {}) {
        return Swal.fire(
            this.parseOptions('info', message, options)
        );
    }

    error(message, options = {}) {
        if (!message) {
            message = "An error occured!";
        }

        return Swal.fire(
            this.parseOptions('error', message, options)
        );
    }

    warning(message, options = {}) {
        return Swal.fire(
            this.parseOptions('warning', message, options)
        );
    }

    success(message, options = {}) {
        return Swal.fire(
            this.parseOptions('success', message, options)
        );
    }

    show(message, type, options = {}) {
        return Swal.fire(
            this.parseOptions(type, message, options)
        );
    }

    parseOptions(type, message, options) {
        var options = Object.assign({
            title: Util.ucfirst(type),
            text: message,
            icon: type
        }, options);

        if (options.icon == 'alert') {
            options.icon = 'error';
        }

        return options;
    }

    confirm(text, options = {}, callback = null) {
        options = Object.assign({ title: 'Are you sure?', showConfirmButton: true, showCancelButton: true }, options);

        return Swal.fire(
            this.parseOptions('warning', text, options)
        );
    }

}

export default Alert;