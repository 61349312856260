import { useToast, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
class Toaster {

    constructor() {
        this.toast = useToast({
            position: 'bottom-right',
            timeout: 5000
        });
    }

    show(message, type = null) {
        if (!type) {
            type = TYPE.SUCCESS;
        }

        return this.toast(message, {
            type : type
        });
    }

    error(message) {
        if (message == null) {
            message = "An error occured!";
        }

        this.show(message, TYPE.ERROR);
    }

    success(message) {
        if (message == null) {
            message = "Process completed without errors!";
        }

        this.show(message, TYPE.succ);
    }
}

export default Toaster;